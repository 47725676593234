import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaPhone, FaInstagram } from 'react-icons/fa';

function App() {
  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 758);

  // Detect if the screen is mobile
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 758);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Toggle tooltip visibility for mobile
  const toggleTooltip = (icon: string) => {
    if (isMobile) {
      setVisibleTooltip((prev) => (prev === icon ? null : icon));
    }
  };

  return (
    <div
      className="h-screen w-screen bg-cover relative overflow-hidden"
      style={{
        backgroundImage: isMobile ? "url('/15mobile.webp')" : "url('/16.webp')",
        backgroundPosition: isMobile ? "28% center" : "75% center",
      }}
    >
      {/* Bottom-left icons */}
      <div className="fixed bottom-4 left-4 flex flex-col gap-6 items-start">
        {/* Mail Icon */}
        <div className="relative group">
          <a
            href="mailto:info@socik.sk"
            onClick={(e) => {
              if (isMobile) {
                e.preventDefault();
                toggleTooltip('mail');
              }
            }}
            className="cursor-pointer"
          >
            <FaEnvelope 
              className={`text-[#6C3BAA] text-4xl transition-transform duration-300 
                ${visibleTooltip === 'mail' ? 'scale-110' : ''} 
                sm:group-hover:scale-110`}
            />
          </a>
          {/* Tooltip */}
          <div
            className={`absolute top-1/2 -translate-y-1/2 left-full ml-2 bg-[#EBE9EE] text-[#1B0A31] px-3 py-2 rounded-lg shadow-md text-sm whitespace-nowrap 
              ${visibleTooltip === 'mail' ? 'block' : 'hidden'} 
              sm:group-hover:block`}
          >
            <a href="mailto:info@socik.sk" className="underline sm:no-underline">
              info@socik.sk
            </a>
          </div>
        </div>

        {/* Phone Icon */}
        <div className="relative group">
          <a
            href="tel:+421908269336"
            onClick={(e) => {
              if (isMobile) {
                e.preventDefault();
                toggleTooltip('phone');
              }
            }}
            className="cursor-pointer"
          >
            <FaPhone 
              className={`text-[#6C3BAA] text-4xl transition-transform duration-300 
                ${visibleTooltip === 'phone' ? 'scale-110' : ''} 
                sm:group-hover:scale-110`}
            />
          </a>
          {/* Tooltip */}
          <div
            className={`absolute top-1/2 -translate-y-1/2 left-full ml-2 bg-[#EBE9EE] text-[#1B0A31] px-3 py-2 rounded-lg shadow-md text-sm whitespace-nowrap 
              ${visibleTooltip === 'phone' ? 'block' : 'hidden'} 
              sm:group-hover:block`}
          >
            <a href="tel:+421908269336" className="underline sm:no-underline">
              +421 908 269 336
            </a>
          </div>
        </div>

        {/* Instagram Icon */}
        <div className="relative group">
          <a
            href="https://instagram.com/socik_me"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer"
            onClick={() => setVisibleTooltip(null)}
          >
            <FaInstagram 
              className="text-[#6C3BAA] text-4xl group-hover:scale-110 transition-transform duration-300" 
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
